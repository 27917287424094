import { graphql } from 'gatsby'
import ContentBanner from '../components/ContentBanner'
import GatsbyHead from '../components/GatsbyHead'
import LayoutWrapper from '../components/LayoutWrapper'
import MarkdownContent from '../components/MarkdownContent'

export const query = graphql`
  query Country($contentful_id: String!, $locale: String!) {
    contentfulCountry(contentful_id: { eq: $contentful_id }, node_locale: { eq: $locale }) {
      heading
      title
      description {
        description
      }
      content {
        childMarkdownRemark {
          htmlAst
          images {
            contentful_id
            title
            localFile {
              childImageSharp {
                gatsbyImageData(width: 760)
              }
              publicURL
              extension
            }
          }
        }
      }
    }
  }
`

const Country = ({
  pageContext: { translatedPages },
  data: {
    contentfulCountry: {
      heading,
      description: { description },
      content: {
        childMarkdownRemark: { htmlAst, images },
      },
    },
  },
}) => (
  <LayoutWrapper translatedPages={translatedPages}>
    <ContentBanner
      title={heading}
      description={description}
    />
    <MarkdownContent
      el={htmlAst}
      images={images}
    />
  </LayoutWrapper>
)

/** @type {import('gatsby').HeadFC} */
export const Head = ({
  pageContext: { canonicalUrl, translatedPages },
  data: {
    contentfulCountry: {
      title,
      description: { description },
    },
  },
}) => {
  return (
    <GatsbyHead
      pageTitle={title}
      metaDescription={description}
      canonicalUrl={canonicalUrl}
      translatedPages={translatedPages}
    />
  )
}

export default Country
