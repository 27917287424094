import styled from 'styled-components'
import { legacyBreakpoints } from '../utils/media'
import Wrapper from './Wrapper'

const ContentWrapper = styled.div`
  position: relative;
  text-align: center;
  padding-top: 160px;
  padding-bottom: 50px;
  width: 100%;
  background-color: var(--color-background-light);

  h1,
  p {
    max-width: 600px;
    margin-right: auto;
    margin-left: auto;
  }

  p {
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 0;
  }

  @media screen and (max-width: ${legacyBreakpoints.mobile}) {
    padding: 100px 20px 50px;
  }
`

const ContentBanner = ({ title, description }) => {
  return (
    <ContentWrapper>
      <Wrapper>
        {title && <h1>{title}</h1>}
        {description && <p>{description}</p>}
      </Wrapper>
    </ContentWrapper>
  )
}

export default ContentBanner
